// Add mixins for Gill Sans Nova variants
@mixin font-book() {
  font-weight: 400;
  font-style: normal;
}

@mixin font-book-italic() {
  font-weight: 400;
  font-style: italic;
}

@mixin font-medium() {
  font-weight: 500;
  font-style: normal;
}

@mixin font-medium-italic() {
  font-weight: 500;
  font-style: italic;
}

@mixin font-bold() {
  font-weight: 700;
  font-style: normal;
}

@mixin font-bold-italic() {
  font-weight: 700;
  font-style: italic;
}
