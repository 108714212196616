.Footer {
  // @include content-spacing(large, 'margin-top');
  display: block;
  background-color: $oil;
}

.Footer-container {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.Footer-inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  padding-top: 60px;
  padding-bottom: 100px;

  @include breakpoint(medium) {
    grid-template-columns: repeat(12, 1fr);
    padding-top: 80px;
    padding-bottom: 60px;
  }
  @include breakpoint(xlarge) {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}

.Footer-mainMenu {
  grid-column: 1 / 4;

  @include breakpoint(medium) {
    grid-column: 1 / 13;
    margin-bottom: 30px;
  }
  @include breakpoint(large) {
    grid-column: 3 / 11;
    margin-bottom: 50px;
  }

  .Footer-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;

    @include breakpoint(medium) {
      grid-template-columns: repeat(10, 1fr);
    }

    li {
      @include breakpoint(medium) {
        &:nth-child(1) {
          grid-column: 1 / 3;
        }

        &:nth-child(2) {
          grid-column: 3 / 7;
          padding-left: 40px;
        }

        &:nth-child(3) {
          grid-column: 7 / 11;
          padding-left: 20px;
        }
      }
    }

    a {
      color: $white;
    }
  }
}

.Footer-subMenu {
  grid-column: 1 / 4;
  margin-bottom: 40px;

  @include breakpoint(medium) {
    grid-column: 1 / 13;
    margin-bottom: 70px;
  }
  @include breakpoint(large) {
    grid-column: 3 / 11;
    margin-bottom: 80px;
  }

  .Footer-menuWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    width: 100%;

    @include breakpoint(medium) {
      grid-template-columns: repeat(10, 1fr);
    }

    .Footer-list {
      @include breakpoint(small only) {
        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          grid-column: 2 / 3;
        }

        &:nth-child(4) {
          grid-column: 3 / 4;
        }
      }
      @include breakpoint(medium) {
        &:nth-child(1) {
          grid-column: 1 / 3;
        }

        &:nth-child(2) {
          grid-column: 3 / 7;
          padding-left: 40px;
        }

        &:nth-child(3) {
          grid-column: 7 / 9;
          padding-left: 20px;
        }

        &:nth-child(4) {
          grid-column: 9 / 11;
          text-align: right;
        }
      }
    }
  }
}

.Footer-metaMenu {
  grid-column: 1 / 4;
  margin-bottom: 15px;

  @include breakpoint(medium) {
    grid-column: 1 / 13;
    margin-bottom: 0;
  }
  @include breakpoint(large) {
    grid-column: 3 / 11;
  }

  .Footer-list {
    @include breakpoint(small only) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
      width: 100%;
    }
    @include breakpoint(medium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    li:nth-child(1) {
      @include breakpoint(small only) {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        margin-right: -30px;
        margin-left: -30px;
        padding: 15px 30px;
        background-color: $jet;

        a {
          padding: 10px 0;
          font-size: rem-calc(15);
          line-height: unitless-calc(25, 15);
        }
      }

      a {
        color: $white;
      }
    }

    li:nth-child(2) {
      @include breakpoint(small only) {
        grid-column: 2 / 4;
      }
    }

    a {
      font-size: rem-calc(16);
      line-height: unitless-calc(20, 16);
    }
  }
}

.Footer-list {
  a {
    @include font-medium();
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 25px;
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);
    text-transform: uppercase;
    color: $aluminum;

    @include breakpoint(medium) {
      padding-top: 2px;
      padding-bottom: 8px;
    }
    @include breakpoint(large) {
      padding-top: 4px;
      padding-bottom: 10px;
      font-size: rem-calc(20);
      line-height: unitless-calc(26, 20);
    }

    &:hover {
      text-decoration: underline;
    }

    span {
      @include breakpoint(small only) {
        display: none;
      }
    }
  }
}
