@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}

.modal-video {
  position: fixed;
  z-index: map_get($z-index, modal);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  animation-name: modal-video;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  opacity: 1;
  background-color: rgba($black, .8);
  transition: opacity .3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
  transform: translate(0, 100px);
}

.modal-video-body {
  display: table;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @include breakpoint(large) {
    max-width: 1340px;
  }
}

.modal-video-inner {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;

  @include breakpoint(large) {
    padding-right: 80px;
    padding-left: 80px;
  }
}

.modal-video-movie-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  transform: translate(0, 0);
  animation-name: modal-video-inner;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  background-color: $oil;
  transition: transform .3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: map_get($z-index, modal);
  top: -60px;
  right: 0;
  display: inline-block;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border: 0;
  background: $black;

  @include breakpoint(large) {
    top: 0;
    right: -50px;
    width: 50px;
    height: 50px;
  }

  &:hover {
    cursor: pointer;

    &::before,
    &::after {
      background: $aluminum;
    }
  }
}

.modal-video-close-btn::before {
  transform: rotate(45deg);
}

.modal-video-close-btn::after {
  transform: rotate(-45deg);
}

.modal-video-close-btn::before,
.modal-video-close-btn::after {
  position: absolute;
  top: 50%;
  left: 22.5%;
  width: 52.59%;
  height: 2px;
  content: '';
  border-radius: 0;
  background: $white;
}
