// Styles for error page
.Error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 75px);
  padding: 0 30px;
  transform: translateY(-75px);

  @include breakpoint(large) {
    height: calc(100vh - 95px);
    transform: translateY(-95px);
  }
}

.Error-title {
  font-size: rem-calc(49);
  line-height: unitless-calc(49, 49);
  text-align: center;
  text-transform: uppercase;

  @include breakpoint(large) {
    font-size: rem-calc(60);
    line-height: unitless-calc(60, 60);
  }
}

.Error-button {
  max-width: 250px;
  margin: 30px auto 0;

  @include breakpoint(large) {
    margin-top: 50px;
  }
}
