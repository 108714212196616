.Filter {
  @include content-spacing(small, 'margin-top');
  @include content-spacing(small, 'margin-bottom');
}

.Filter-inner {
  max-width: $global-width;
  margin: 0 auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.Filter-trigger {
  @include font-medium();
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 0;
  font-size: rem-calc(18);
  line-height: unitless-calc(18, 18);
  text-transform: uppercase;
  color: $aluminum;
  border: 0;
  outline: none;
  background-color: transparent;
  transition: color 150ms ease-in-out;

  @include breakpoint(large) {
    margin-bottom: 0;
    font-size: rem-calc(20);
  }

  &:hover {
    cursor: pointer;
    color: $blue-spindle;
  }
}

.Filter-head {
  display: none;

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    margin-bottom: 95px;

    .Filter-trigger {
      margin-left: auto;
      color: $white;

      &:hover {
        color: $blue-spindle;
      }
    }
  }
}

.Filter-item {
  margin-right: auto;
  font-size: rem-calc(18);
  line-height: unitless-calc(20, 18);
  text-transform: uppercase;
  color: $aluminum;
  transition: color 150ms ease-in-out;

  @include breakpoint(xlarge) {
    font-size: rem-calc(20);
    line-height: unitless-calc(20, 20);
  }

  &:hover {
    cursor: pointer;
    color: $blue-spindle;
  }
}

.Filter-triggerSvg {
  width: 9px;
  height: 17px;
  margin-left: 15px;
}

.Filter-container {
  display: flex;
  flex-wrap: wrap;
}

// Filter submenu & overlay
.Filter-overlay,
.Filter-submenu {
  position: fixed;
  z-index: 1001;
  top: 0;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  transition: right 450ms cubic-bezier(.250, .460, .450, .940);
}

.Filter-submenu {
  right: -100%;
}

.Filter-submenuInner {
  position: relative;
  max-width: $global-width;
  height: auto;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  color: $black;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 95px 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
  }
}

.Filter-submenuContent {
  position: relative;
  z-index: map_get($z-index, menu);

  @include breakpoint(large) {
    display: grid;
    grid-area: 2 / 7 / 3 / 13;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
  }

  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    content: ' ';
    background-color: $blue-spindle;

    @include breakpoint(large) {
      left: 0;
      transform: translateY(-95px);
    }
  }
}

.Filter-submenuHead {
  position: absolute;
  z-index: map_get($z-index, menu) + 1;
  right: #{$grid-margin-gutters / 2};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 75px;
  color: $black;

  @include breakpoint(large) {
    right: 0;
    grid-area: 1 / 7 / 2 / 13;
    height: 95px;
  }
}

.Filter-submenuContentInner {
  width: 100%;

  @include breakpoint(large) {
    grid-column-start: 2;
    grid-column-end: 7;
    padding: 0 30px 0 0;
    transform: translateY(-98px);
  }
}

// Filter selections
.Filter-selection {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 15px 20px;
  font-size: rem-calc(18);
  line-height: unitless-calc(18, 18);
  text-transform: uppercase;
  background-color: $jet;

  @include breakpoint(large) {
    font-size: rem-calc(20);
  }

  &:hover {
    cursor: pointer;
    user-select: none;
    color: rgba($white, .5);
    outline: none;

    .Filter-selectionSvg {
      color: $white;
    }
  }
}

.Filter-selectionSvg {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  transform: translateY(1px);
}

.Filter-close {
  display: inline-flex;
  padding: 10px;
  transform: translateX(10px);
  border: 0;
  outline: none;
  background-color: transparent;

  @include breakpoint(large) {
    transform: translateX(-20px);
  }
  &:hover {
    cursor: pointer;
  }
}

.Filter-closeSvg {
  width: 15px;
  height: 15px;

  @include breakpoint(large) {
    width: 15px;
    height: 15px;
  }
}

// Filter accordion
.Filter-accordion {
  position: relative;
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: calc(100% - 60px);
  min-height: 75px;
  max-height: calc((var(--vh, 1vh) * 100) - 150px);
  margin: 0 30px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba($black, .15);

  @include breakpoint(medium) {
    max-height: calc((var(--vh, 1vh) * 100) - 195px);
  }

  @include breakpoint(large) {
    width: 100%;
    max-height: calc((var(--vh, 1vh) * 100) - 189px);
    margin: 0;
  }
}

.Filter-accordionHead {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 29px 0 28px;
  font-size: rem-calc(18);
  line-height: unitless-calc(18, 18);
  background-color: $blue-spindle;

  @include breakpoint(large) {
    padding: 40px 0;
    font-size: rem-calc(20);
  }

  span {
    color: $black;
    transition: color 90ms ease-in;
  }

  &:hover {
    cursor: pointer;

    & > span {
      color: rgba($black, .5);
      transition: color 0ms ease-in;
    }
  }
}

.Filter-accordionSvg {
  width: 15px;
  height: 15px;
  margin-left: 25px;
}

.Filter-accordionItems {
  display: none;
  height: 0;
  padding: 0;
}

.Filter-accordionItem {
  padding-bottom: 30px;
  font-size: rem-calc(18);
  line-height: unitless-calc(18, 18);
  break-inside: avoid;
  color: $black;
  transition: color 90ms ease-in;

  @include breakpoint(large) {
    font-size: rem-calc(20);
  }

  &:hover {
    cursor: pointer;
    color: rgba($black, .5);
    transition: color 0ms ease-in;
  }
}

// State classes
.Filter-trigger.is-mobile {
  @include breakpoint(large) {
    display: none;
  }
}

.Filter.is-open {
  .Filter-overlay {
    z-index: map_get($z-index, menu);
    right: 0;
    background-color: rgba($black, .6);
  }

  .Filter-submenu {
    z-index: map_get($z-index, menu) + 1;
    right: 0;
    pointer-events: auto;
  }
}

.Filter-accordionItems.is-columns {
  @include breakpoint(large) {
    column-count: 2;
  }
}

.Filter-accordion.is-open {
  min-height: calc((var(--vh, 1vh) * 100) - 150px);

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint(large) {
    min-height: auto;
    max-height: calc((var(--vh, 1vh) * 100) - 265px);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .Filter-accordionHead {
    cursor: default;
    & > span {
      color: $aluminum;
    }

    &:hover > span {
      color: $aluminum;
    }
  }

  .Filter-accordionSvg {
    display: none;
  }

  .Filter-accordionItems {
    display: block;
    height: auto;
  }
}
