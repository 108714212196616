.PageTransition {
  position: absolute;
  z-index: map_get($z-index, overlay);
  top: 0;
  display: none;
  width: 100%;
  height: 100vh;
  pointer-events: none;

  #{--PageTransition-background-color}: $white;
}

.PageTransition-top {
  width: 100%;
  height: 50%;
  background: var(--PageTransition-background-color);
}

.PageTransition-bottom {
  bottom: 0;
  width: 100%;
  height: 50%;
  background: var(--PageTransition-background-color);
}

.PageTransition-title {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 50%;
}

.PageContainer {
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }
}

.PageTransition.PageTransition--show {
  display: block;
}

.PageTransition.PageTransition--redCinnabar {
  #{--PageTransition-background-color}: $red-cinnabar;

  .AnimatedTitle {
    #{--AnimatedTitle-title-color}: $white;
  }
}

.PageTransition.PageTransition--redRoman {
  #{--PageTransition-background-color}: $red-roman;

  .AnimatedTitle {
    #{--AnimatedTitle-title-color}: $white;
  }
}

.PageTransition.PageTransition--yellowFizz {
  #{--PageTransition-background-color}: $yellow-fizz;

  .AnimatedTitle {
    #{--AnimatedTitle-title-color}: $white;
  }
}

.PageTransition.PageTransition--greenMoss {
  #{--PageTransition-background-color}: $green-moss;

  .AnimatedTitle {
    #{--AnimatedTitle-title-color}: $white;
  }
}

.PageTransition.PageTransition--blueSpindle {
  #{--PageTransition-background-color}: $blue-spindle;

  .AnimatedTitle {
    #{--AnimatedTitle-title-color}: $white;
  }
}
