.Quote {
  @include content-spacing(large, 'margin-top');
  @include content-spacing(large, 'margin-bottom');
  display: block;
  overflow: hidden;
  color: var(--Quote-text-color);
}

.Quote-container {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.Quote-inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.Quote-main {
  position: relative;
  grid-column: 1 / 4;
  background-color: var(--Quote-background-color);

  @include breakpoint(large) {
    grid-column: 3 / 11;
    order: 2;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 100%;
    width: 50vw;
    height: 100%;
    content: '';
    background-color: var(--Quote-background-color);
  }
}

.Quote-text {
  @include content-spacing(medium, 'padding-top');
  @include content-spacing(medium, 'padding-bottom');
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.Quote-content {
  position: relative;
  grid-column: 1 / 4;
  margin-bottom: 30px;
  padding-left: 60px;
  font-size: rem-calc(28);
  line-height: 1;

  @include breakpoint(large) {
    font-size: rem-calc(40);
  }
  @include breakpoint(medium) {
    padding-right: 120px;
    padding-left: 120px;
  }
  @include breakpoint(large) {
    grid-column: 3 / 9;
    margin-bottom: 50px;
    padding: 0;
  }

  &::before {
    position: absolute;
    width: 20px;
    margin-left: -30px;
    content: '“';

    @include breakpoint(large) {
      display: block;
      width: calc(#{percentage(1 / 6)} - 20px);
      margin-left: -40px;
    }
  }

  h2 {
    &::after {
      display: inline-block;
      content: '”';
      transform: translate(13px, 4px);

      @include breakpoint(medium) {
        display: inline-block;
        transform: translate(17px, 5px);
      }
    }
  }
}

.Quote-author {
  @include font-medium();
  grid-column: 1 / 4;
  margin-bottom: 25px;
  padding-left: 60px;
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);

  @include breakpoint(medium) {
    margin-bottom: 45px;
    padding-right: 120px;
    padding-left: 120px;
  }
  @include breakpoint(large) {
    grid-column: 3 / 9;
    margin-bottom: 0;
    padding-left: 0;
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }
}

.Quote-aside {
  position: relative;
  grid-column: 1 / 2;
  width: 104px;
  margin-top: -52px;

  @include breakpoint(medium) {
    width: 156px;
    margin-top: -78px;
  }
  @include breakpoint(large) {
    grid-column: 1 / 3;
    order: 1;
    width: auto;
    margin-top: 0;
    margin-right: -20px;
  }
}

.Quote-figure {
  position: relative;
  display: block;
  height: 0;
  padding-bottom: 100%;
  background-color: var(--Quote-background-color);

  .Quote--default & {
    background-color: $white;
  }
}

.Quote-image {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
}

.Quote.Quote--default {
  #{--Quote-background-color}: $tuatara;
  #{--Quote-text-color}: $white;
}

.Quote.Quote--redRoman {
  #{--Quote-background-color}: $red-roman;
  #{--Quote-text-color}: $white;
}

.Quote.Quote--yellowFizz {
  #{--Quote-background-color}: $yellow-fizz;
  #{--Quote-text-color}: $black;
}

.Quote.Quote--greenMoss {
  #{--Quote-background-color}: $green-moss;
  #{--Quote-text-color}: $black;
}

.Quote.Quote--blueSpindle {
  #{--Quote-background-color}: $blue-spindle;
  #{--Quote-text-color}: $black;
}
