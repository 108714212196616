.ThemeCard {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  #{--ThemeCard-background-color}: $boulder;

  &:hover {
    .ThemeCard-image {
      transform: scale(1.1);
    }
    .ThemeCard-content {
      opacity: 0.7;
    }
  }
}

.ThemeCard-main {
  position: relative;
  grid-column: 1 / 4;
  background-color: var(--ThemeCard-background-color);
}

.ThemeCard-content {
  padding: 45px 30px 80px 40px;
  transition: opacity 300ms ease-in-out;
}

.ThemeCard-label {
  @include font-medium();
  display: inline-block;
  margin-bottom: 30px;
  padding-right: 39px;
  text-transform: uppercase;
  color: $black;
}

.ThemeCard-labelText {
  display: inline;
  margin-right: 15px;
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);
}

.ThemeCard-labelArrow {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: -24px;
  transform: translateY(5px);
  margin-top: -7px;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.ThemeCard-title {
  @include font-medium();
  @include break-word();
  display: flex;
  font-size: rem-calc(22);
  line-height: 1;
  text-transform: uppercase;
  color: $black;
  @include breakpoint(medium) {
    font-size: rem-calc(32);
  }
}

.ThemeCard-curatedBy {
  display: flex;
  flex-direction: column;
  color: var(--ThemeHeader-color);
  margin-top: 33px;
  margin-bottom: -50px;
  font-size: rem-calc(14);
  line-height: unitless-calc(16, 14);

  @include breakpoint(medium) {
    flex-direction: row;

    margin-top: 17px;
    margin-bottom: 3px;
    width: 100%;
    font-size: rem-calc(16);
    line-height: unitless-calc(16, 20);
  }

  @include breakpoint(large) {
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }

}

.ThemeCard-author {
  margin-right: 5px;
}

.ThemeCard-aside {
  position: relative;
  grid-column: 1 / 4;
  width: 100%;
}


.ThemeCard-figure {
  position: relative;
  display: block;
  overflow: hidden;
}

.ThemeCard-figure::before {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: percentage(5 / 8);
  content: '';
  background-color: var(--ThemeCard-background-color);
}

.ThemeCard-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  will-change: transform;
  transition: transform 0.65s cubic-bezier(0.455, 0.030, 0.515, 1);
}

// ThemeCard Color variation modifiers

.ThemeCard.ThemeCard--redRoman {
  #{--ThemeCard-background-color}: $red-roman;
}

.ThemeCard.ThemeCard--yellowFizz {
  #{--ThemeCard-background-color}: $yellow-fizz;
}

.ThemeCard.ThemeCard--greenMoss {
  #{--ThemeCard-background-color}: $green-moss;
}

.ThemeCard.ThemeCard--blueSpindle {
  #{--ThemeCard-background-color}: $blue-spindle;
}

// CardList stacked parent component styles
.CardList--stacked .ThemeCard {
  @include content-spacing(small, 'margin-bottom');

  @include breakpoint(medium) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.CardList--stacked .ThemeCard:nth-child(odd) .ThemeCard-main {
  @include breakpoint(medium) {
    grid-column: 5 / 13;
    order: 2;
  }
}

.CardList--stacked .ThemeCard:nth-child(even) .ThemeCard-main {
  @include breakpoint(medium) {
    grid-column: 1 / 9;
    order: 1;
  }
}

.CardList--stacked .ThemeCard-content {
  @include breakpoint(medium) {
    padding: 35px 30px 40px 40px;
  }
  @include breakpoint(large) {
    padding: 55px 40px 60px 60px;
  }
}

.CardList--stacked .ThemeCard-label {
  @include breakpoint(medium) {
    margin-bottom: 15px;
  }
  @include breakpoint(large) {
    margin-bottom: 25px;
    padding-right: 44px;
  }
}

.CardList--stacked .ThemeCard-labelText {
  @include breakpoint(large) {
    margin-right: 20px;
    font-size: rem-calc(20);
    line-height: unitless-calc(24, 20);
  }
}

.CardList--stacked .ThemeCard-title {
  @include breakpoint(small only) {
    @include break-word();
    font-size: rem-calc(24);
  }
  @include breakpoint(medium) {
    font-size: rem-calc(32);
  }
  @include breakpoint(large) {
    font-size: rem-calc(50);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(60);
  }
}

.CardList--stacked .ThemeCard-aside {
  @include breakpoint(medium) {
    width: auto;
  }
}

.CardList--stacked .ThemeCard:nth-child(odd) .ThemeCard-aside {
  @include breakpoint(medium) {
    grid-column: 1 / 5;
    order: 1;
    margin-right: -20px;
  }
}

.CardList--stacked .ThemeCard:nth-child(even) .ThemeCard-aside {
  @include breakpoint(medium) {
    grid-column: 9 / 13;
    order: 2;
    margin-left: -20px;
  }
}


// CardList spread parent component styles
.CardList--spread .ThemeCard {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - #{($grid-margin-gutters + 60px)});

  @include breakpoint(medium) {
    min-width: 350px;
  }
}

.CardList--spread .ThemeCard-main {
  height: 100%;
}

.CardList--spread .ThemeCard-title {
  @include break-word();
}
