.Gallery {
  @include content-spacing(medium, 'margin-bottom');
  display: block;
}

.Gallery-inner {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.Gallery-figure {
  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
  }
}

.Gallery-figureWrap {
  width: calc(100% + #{$grid-margin-gutters});
  margin-right: calc(#{$grid-margin-gutters / 2} * -1);
  margin-left: calc(#{$grid-margin-gutters / 2} * -1);

  @include breakpoint($global-width) {
    width: 100vw;
    margin-right: calc(((100vw - #{$global-width - $grid-margin-gutters}) / 2) * -1);
    margin-left: calc(((100vw - #{$global-width - $grid-margin-gutters}) / 2) * -1);
  }
}

.Gallery-container {
  position: relative;
  overflow: hidden;
  padding-top: 40px;
}

.Gallery-wrapper {
  position: relative;
  display: flex;
}

.Gallery-item {
  min-width: 100%;
}

.Gallery-itemWrap {
  position: relative;
  display: block;
}

.Gallery-itemWrap::before {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: percentage(3 / 4);
  content: '';
  background-color: var(--base-preloaderColor);

  @include breakpoint(medium) {
    padding-bottom: percentage(9 / 16);
  }
}

.Gallery-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

// Gallery navigation

.Gallery-navigation {
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  width: 100px;
  cursor: pointer;
  opacity: 0;
  outline: 0;
  transition: opacity 180ms ease-in;

  @include breakpoint(large) {
    display: flex;
  }
}

.Gallery-container--initialized .Gallery-navigation:not(.is-disabled) {
  pointer-events: all;
  opacity: 1;
}

.Gallery-navigation.is-disabled {
  pointer-events: none;
}

.Gallery-navigation.Gallery-navigation--prev {
  left: 0;
}

.Gallery-navigation.Gallery-navigation--next {
  right: 0;
}

.Gallery-navigationButton {
  position: absolute;
  z-index: 10;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  pointer-events: none;
  background-color: rgba($black, .7);
  transition: background-color 180ms ease-in;

  &:hover {
    background-color: rgba($black, .85);
  }
}

.Gallery-navigation--prev .Gallery-navigationButton {
  left: 0;
  padding-right: 5px;
}

.Gallery-navigation--next .Gallery-navigationButton {
  right: 0;
  padding-left: 5px;
}

.Gallery-navigation.Gallery-navigation--prev:active .Gallery-navigationButton {
  padding-right: 7px;
}

.Gallery-navigation.Gallery-navigation--next:active .Gallery-navigationButton {
  padding-left: 7px;
}

.Gallery-navigationIcon {
  width: 14px;
  height: 24px;
  color: $white;
}


// Gallery pagination
.Gallery-pagination {
  position: absolute;
  z-index: 10;
  top: 3px;
  left: 25px;
  width: 100%;
  transform: translate3d(0,0,0);
  text-align: left;
  transition: 300ms opacity;

  @include breakpoint(large) {
    display: none;
  }
}

.Gallery-paginationBullet {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 5px;
  cursor: pointer;
  opacity: 1;
  border: 1px solid --base-textColor;
  border-radius: 50%;
  outline: none;
  background: transparent;
  transition: background-color 180ms ease-in;

  .is-project & {
    border:solid 1px $white;
  }
}

.Gallery-paginationBullet--active {
  opacity: 1;
  background: --base-textColor;

  .is-project & {
    background-color: $white;
  }
}

.Gallery-caption {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.Gallery-captionWrap {
  @include content-spacing(small, 'margin-top');
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 12;
  }

  p {
    @include font-medium();
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);

    @include breakpoint(large) {
      font-size: rem-calc(20);
      line-height: unitless-calc(26, 20);
    }
  }
}

// Gallery caption counter (desktop)
.Gallery-captionCount {
  display: none;

  @include breakpoint(large) {
    display: inline-block;
  }
}

.Gallery-captionCopy {
  @include font-medium-italic();
  opacity: 0;
  transition-duration: 0ms;
}

.Gallery-captionSplit {
  opacity: 0;
  transition-duration: 0ms;
}

.has-copy .Gallery-captionCopy,
.has-copy .Gallery-captionSplit {
  opacity: 1;
  transition: opacity 180ms ease-in;
}

// Gallery slide counter (mobile)
.Gallery-slideCount {
  position: absolute;
  top: 0;
  right: 30px;

  @include breakpoint(large) {
    display: none;
  }

  p {
    @include font-medium();
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);
  }
}
