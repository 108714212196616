.NewsCard {
  position: relative;

  #{--NewsCard-background-color}: $white;

  &:hover {
    .NewsCard-image {
      transform: scale(1.1);

      &.is-normal {
        opacity: 1;
      }
    }

    .NewsCard-label,
    .NewsCard-title {
      opacity: 0.7;
    }
  }
}

.NewsCard-main {
  position: relative;
  display: flex;
  grid-column: 1 / 4;
}

.NewsCard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 35px;
  background-color: var(--NewsCard-background-color);
}

.NewsCard-label {
  @include font-medium();
  display: inline-block;
  text-transform: uppercase;

  transition: opacity 300ms ease-in-out;
}

.NewsCard-labelText {
  display: inline;
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);
}

.NewsCard-title {
  @include font-medium();
  @include break-word();
  position: relative;
  display: block;
  margin-bottom: 35px;
  font-size: rem-calc(28);
  line-height: unitless-calc(28, 28);
  text-transform: uppercase;

  transition: opacity 300ms ease-in-out;

  @include breakpoint(medium) {
    font-size: rem-calc(23);
  }
}

.NewsCard-aside {
  position: relative;
  display: flex;
  grid-column: 1 / 4;
  flex-direction: column;
  width: auto;
}

.NewsCard-figure {
  position: relative;
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 100%;
  background-color: var(--NewsCard-background-color);

  overflow: hidden;
}

.NewsCard-figure::before {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: percentage(5 / 8);
  content: '';
}

.NewsCard-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  mix-blend-mode: multiply;
  will-change: transform;
  transition: transform 0.65s cubic-bezier(0.455, 0.030, 0.515, 1), opacity 300ms ease-in-out;

  &.is-normal {
    mix-blend-mode: normal;
    opacity: 0;
  }
}

// NewsCard Color variation modifiers
.NewsCard.NewsCard--redRoman {
  #{--NewsCard-background-color}: $red-roman;
}

.NewsCard.NewsCard--yellowFizz {
  #{--NewsCard-background-color}: $yellow-fizz;
}

.NewsCard.NewsCard--greenMoss {
  #{--NewsCard-background-color}: $green-moss;
}

.NewsCard.NewsCard--blueSpindle {
  #{--NewsCard-background-color}: $blue-spindle;
}

// CardList overview parent component styles
.CardList--overview .NewsCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @include breakpoint(medium) {
    margin-bottom: 60px;
  }
  @include breakpoint(large) {
    margin-bottom: 80px;
  }
}

.CardList--overview .NewsCard-main {
  height: 100%;
}

.CardList--overview .NewsCard-title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}


// CardList stacked parent component styles
.CardList--stacked .NewsCard {
  @include content-spacing(small, 'margin-bottom');
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(medium) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.CardList--stacked .NewsCard-main {
  position: relative;
  display: block;
  grid-column: 1 / 4;

  @include breakpoint(medium) {
    width: auto;
  }
}

.CardList--stacked .NewsCard-content {
  height: auto;

  @include breakpoint(medium) {
    padding: 45px 35px;
  }
}

.CardList--stacked .NewsCard-aside {
  position: relative;
  display: flex;
  grid-column: 1 / 4;
  flex-direction: column;
  width: auto;
}

.CardList--stacked .NewsCard:nth-child(even) .NewsCard-main {
  @include breakpoint(medium) {
    grid-column: 1 / 7;
    order: 1;
    margin-right: 0;
  }
  @include breakpoint(large) {
    grid-column: 1 / 5;
    margin-right: 0;
  }
}

.CardList--stacked .NewsCard:nth-child(odd) .NewsCard-main {
  @include breakpoint(medium) {
    grid-column: 7 / 13;
    order: 2;
    margin-left: 0;
  }
  @include breakpoint(large) {
    grid-column: 9 / 13;
    margin-left: 0;
  }
}

.CardList--stacked .NewsCard:nth-child(even) .NewsCard-aside {
  @include breakpoint(medium) {
    grid-column: 7 / 13;
    order: 2;
    margin-left: -20px;
  }
  @include breakpoint(large) {
    grid-column: 5 / 13;
  }
}

.CardList--stacked .NewsCard:nth-child(odd) .NewsCard-aside {
  @include breakpoint(medium) {
    grid-column: 1 / 7;
    order: 1;
    margin-right: -20px;
  }
  @include breakpoint(large) {
    grid-column: 1 / 9;
  }
}

// CardList spread parent component styles
.CardList--spread .NewsCard {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - #{($grid-margin-gutters + 60px)});

  @include breakpoint(medium) {
    min-width: 350px;
  }
}

.CardList--spread .NewsCard-main {
  height: 100%;
}

.CardList--spread .NewsCard-title {
  @include break-word();
}
