.ImageBlock {
  // @include content-spacing(large, 'margin-top');
  @include content-spacing(medium, 'margin-bottom');
  display: block;
}

.ImageBlock-container {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.ImageBlock-inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.ImageBlock-figure {
  grid-column: 1 / 4;
}

.ImageBlock.ImageBlock--right .ImageBlock-figure {
  @include breakpoint(large) {
    grid-column: 3 / 13;
  }
}

.ImageBlock.ImageBlock--left .ImageBlock-figure {
  @include breakpoint(large) {
    grid-column: 1 / 11;
  }
}

.ImageBlock.ImageBlock--split .ImageBlock-figure {
  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
  }
  @include breakpoint(large) {
    grid-column: 1 / 13;
  }
}

.ImageBlock.ImageBlock--center .ImageBlock-figure {
  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
  }
  @include breakpoint(large) {
    grid-column: 1 / 13;
  }
}


.ImageBlock-figureWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: calc(100% + #{$grid-margin-gutters / 2} + 1px);
  max-height: 940px;

  @include breakpoint($global-width) {
    // Breakpoint value exception needed at the point site is centered to break out of grid
    width: calc(100% + ((100vw - #{$global-width - $grid-margin-gutters}) / 2));
  }
}

.ImageBlock.ImageBlock--right .ImageBlock-figureWrap {
  float: left;
}

.ImageBlock.ImageBlock--left .ImageBlock-figureWrap {
  float: right;
}

.ImageBlock.ImageBlock--split .ImageBlock-figureWrap {
  @include breakpoint(small only) {
    width: calc(100% + #{$grid-margin-gutters});
    margin-right: calc(#{$grid-margin-gutters / 2} * -1);
    margin-left: calc(#{$grid-margin-gutters / 2} * -1);
  }

  &:first-child {
    margin-left: calc(#{$grid-margin-gutters / 2} * -1);

    @include breakpoint($global-width) {
      margin-left: calc(((100vw - #{$global-width - $grid-margin-gutters}) / 2) * -1);
    }
  }

  &:last-child {
    margin-right: calc(#{$grid-margin-gutters / 2} * -1);

    @include breakpoint($global-width) {
      margin-right: calc(((100vw - #{$global-width - $grid-margin-gutters}) / 2) * -1);
    }
  }
}

.ImageBlock.ImageBlock--center .ImageBlock-figureWrap {
  width: calc(100% + #{$grid-margin-gutters});
  margin-right: calc(#{$grid-margin-gutters / 2} * -1);
  margin-left: calc(#{$grid-margin-gutters / 2} * -1);

  @include breakpoint($global-width) {
    width: 100vw;
    margin-right: calc(((100vw - #{$global-width - $grid-margin-gutters}) / 2) * -1);
    margin-left: calc(((100vw - #{$global-width - $grid-margin-gutters}) / 2) * -1);
  }
}


.ImageBlock-figureWrap::before {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: percentage(3 / 4);
  content: '';
  background-color: var(--base-preloaderColor);
}

.ImageBlock.ImageBlock--left .ImageBlock-figureWrap::before,
.ImageBlock.ImageBlock--right .ImageBlock-figureWrap::before,
.ImageBlock.ImageBlock--center .ImageBlock-figureWrap::before {
  @include breakpoint(medium) {
    padding-bottom: percentage(9 / 16);
  }
}

.ImageBlock-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


.ImageBlock-caption {
  @include content-spacing(small, 'margin-top');
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 12;
  }

  p {
    @include font-medium-italic();
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);

    @include breakpoint(large) {
      font-size: rem-calc(20);
      line-height: unitless-calc(26, 20);
    }
  }
}
