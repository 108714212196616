.ProjectHeader {
  @include content-spacing(small, 'margin-bottom');
  @include content-spacing(small, 'margin-top');

  #{--ProjectHeader-background-color}: transparent;
  #{--ProjectHeader-color}: $black;

  &:after{
  content: "";
  clear: both;
  display: table;
  }

}

body.is-transitioning .ProjectHeader {
  opacity: 0;
}

.ProjectHeader-inner {
  max-width: $global-width;
  margin: 0 auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.ProjectHeader-intro {
  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
  }
}

.ProjectHeader-backButtonWrapper {
  position: relative;
  display: flex;
  grid-area: 1 / 1 / 2 / 3;
  align-items: flex-start;
  margin-bottom: 25px;
  float: left;
}

.ProjectHeader-backButton {
  margin-left: -20px;
  padding-left: 20px;
  transition: color 0.1s ease-in-out;

  @include breakpoint(large) {
    float: none;
  }

  &:hover {
    color: $blue-spindle;
  }

  svg {
    position: absolute;
    top: 3px;
    left: -20px;
    width: 9px;
    height: 15px;
  }

  span {
    font-size: rem-calc(20);
    line-height: unitless-calc(20, 20);
    text-transform: uppercase;
  }
}


.ProjectHeader-title {
  grid-column: 1/8;
  margin-bottom: 25px;
  font-size: rem-calc(36);
  line-height: unitless-calc(42, 36);
  text-transform: uppercase;

  @include breakpoint(large) {
    grid-column: span 8;
    margin-bottom: 60px;
    transform: translateY(-5px);
    font-size: rem-calc(60);
    line-height: unitless-calc(60, 60);
  }

  h1 {
    @include font-medium();
    margin-bottom: 30px;
    font-size: rem-calc(36);
    line-height: unitless-calc(36, 36);
    text-transform: uppercase;

    @include breakpoint(large) {
      margin-bottom: 40px;
      font-size: rem-calc(40);
    }
  }

  br {
    display: none;

    @include breakpoint(medium) {
      display: inline;
    }
  }
}

.ProjectHeader-duration {
  float: right;

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  @include breakpoint(large) {
    float: none;
    justify-self: end;
    grid-area: 1/9/1/13;

  }

  span {
    font-size: rem-calc(18);
    line-height: unitless-calc(18, 18);
    text-transform: uppercase;
  }
}

.ProjectHeader-text {
  grid-column: span 6;
  font-size: rem-calc(22);
  line-height: unitless-calc(29, 20);
}

.ProjectHeader-content {
  float: left;
  margin-bottom: 25px;

  @include breakpoint(large) {
    float: none;
    display: grid;
    grid-area: 1 / 3 / 2 / 13;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(10, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 25px;
  }
}

.ProjectHeader-media {
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
  }
}

.ProjectHeader-curator {
  display: flex;
  order: 2;
  justify-content: center;
  align-items: flex-end;
  width: 135px;
  height: 135px;
  margin-right: -30px;
  font-size: 0;
  background-color: var(--ProjectHeader-background-color);

  @include breakpoint(large) {
    order: 1;
    width: 100%;
    height: 70%;
    max-height: 370px;
  };
}

.ProjectHeader-curatorImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ProjectHeader-people {
  display: flex;
  order: 2;
  justify-content: space-between;
  align-items: flex-end;
  transform: translateY(-50%);

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 7;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin-right: -20px;
    transform: translateY(0);
  }
}

.ProjectHeader-curatedBy {
  display: flex;
  flex-direction: column;
  order: 1;
  color: var(--ProjectHeader-color);

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 0;
    width: 100%;
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }
}

.ProjectHeader-author {
  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 7;
  }
}

.ProjectHeader-authorRole {
  @include breakpoint(large) {
    grid-area: 2 / 3 / 3 / 7;
  }
}

.ProjectHeader-imageWrapper {
  order: 1;

  @include breakpoint(large) {
    display: flex;
    grid-area: 1 / 7 / 2 / 13;
    order: 2;
    width: 50vw;
  }

  img {
    width: calc(100vw - 30px);
    min-height: 290px;
    max-height: 400px;
    object-fit: cover;

    @include breakpoint(xlarge) {
      max-height: 530px;
    }
  }
}
