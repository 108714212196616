// Root css-variables set as default
:root {
  #{--base-textColor}: $black;
  #{--base-backgroundColor}: $white;
  #{--base-navigationColor}: $white;
  #{--base-preloaderColor}: $smoke;
  #{--base-borderColor}: $iron;
  #{--base-rulerColor}: $black;
}

// Box sizing border box on all elements
* {
  box-sizing: border-box;
}

// Base styling for HTML element
html {
  visibility: visible;
  height: 100%;
  min-height: 100%;
  line-height: unitless-calc(24);
  text-size-adjust: 100%;
  opacity: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // scroll-behavior: smooth;
}

// Base styling for Body element and page states, updating css variables
body {
  @include font-medium();
  position: relative;
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-body;
  font-size: rem-calc(16);
  text-rendering: optimizeLegibility;
  color: var(--base-textColor);
  background-color: var(--base-backgroundColor);
  transition: background-color 0s ease-in-out;
  will-change: background-color;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba($black, 0);
  -webkit-overflow-scrolling: touch;
}

body.is-home {
  #{--base-textColor}: $white;
  #{--base-backgroundColor}: transparent;
  #{--base-navigationColor}: transparent;
}

body.is-project {
  #{--base-textColor}: $white;
  #{--base-backgroundColor}: $black;
  #{--base-navigationColor}: $black;
  #{--base-preloaderColor}: $jet;
  #{--base-borderColor}: $steel;
  #{--base-rulerColor}: $jet;
}

body.is-generic {
  #{--base-backgroundColor}: $gainsboro;
  #{--base-navigationColor}: $gainsboro;
}

// Server side rendering base styling
#__next { // scss-lint:disable IdSelector
  width: 100vw;
  min-height: 100%;
}
