.ProjectCard {
  position: relative;

  #{--ProjectCard-background-color}: $jet;

  &:hover {
    .ProjectCard-image {
      transform: scale(1.1);
    }
    .ProjectCard-label,
    .ProjectCard-title {
      opacity: .7;
    }
  }
}

.ProjectCard-main {
  position: relative;
  display: flex;
  grid-column: 1 / 4;
}

.ProjectCard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 35px;
  background-color: var(--ProjectCard-background-color);
}

.ProjectCard-label {
  @include font-medium();
  display: inline-block;
  text-transform: uppercase;
  transition: opacity 300ms ease-in-out;
}

.ProjectCard-labelText {
  display: inline;
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);
}

.ProjectCard-title {
  @include font-medium();
  position: relative;
  display: block;
  margin-bottom: 35px;
  font-size: rem-calc(22);
  line-height: unitless-calc(30, 30);
  text-transform: uppercase;
  transition: opacity 300ms ease-in-out;
  padding-bottom: 5px;

  @include breakpoint(medium) {
    font-size: rem-calc(24);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(30);
  }
}

.ProjectCard-aside {
  position: relative;
  display: flex;
  grid-column: 1 / 4;
  flex-direction: column;
  width: auto;
  min-height: 50%;

  @include breakpoint(large) {
    min-height: none;
  }
}


.ProjectCard-figure {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  height: auto;
  min-height: 100%;
  background-color: var(--ProjectCard-background-color);
}

.ProjectCard-figure::before {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: percentage(5 / 8);
  content: '';
}

.ProjectCard-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  will-change: transform;
  transition: transform .65s cubic-bezier(0.455, 0.030, 0.515, 1);
}

.CardList .ProjectCard {
  &:nth-child(even) {
    .ProjectCard-main {
      order: 1;
    }
    .ProjectCard-aside {
      order: 2;
    }
  }
}

.CardList.CardList--overview .ProjectCard {
  @include breakpoint(small only) {
    &:nth-child(even) {
      .ProjectCard-main {
        order: 2;
      }
      .ProjectCard-aside {
        order: 1;
      }
    }
  }
}

// CardList spread parent component styles
.CardList--spread .ProjectCard {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - #{($grid-margin-gutters + 60px)});

  @include breakpoint(medium) {
    min-width: 350px;
  }
}

.CardList--spread .ProjectCard-main {
  height: 100%;
}

.CardList--spread .ProjectCard-title {
  @include break-word();
}

// CardList overview parent component styles
.CardList--overview .ProjectCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @include breakpoint(large) {
    margin-bottom: 60px;
  }
}

.CardList--overview .ProjectCard-main {
  height: 100%;
}

.CardList--overview .ProjectCard-title {
  @include break-word();
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

// CardList overview parent component styles large up breakpoint grid exception
.CardList.CardList--overview.CardList--projects:not(.is-filtered) .ProjectCard:nth-child(4n+4) {
  @include breakpoint(large) {
    display: grid;
    grid-column: 1 / 4;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
  }
}

.CardList.CardList--overview.CardList--projects:not(.is-filtered) .ProjectCard:nth-child(4n+4) .ProjectCard-main {
  @include breakpoint(large) {
    height: auto;
    min-height: 50%;
    margin-bottom: auto;
  }
}

.CardList.CardList--overview.CardList--projects:not(.is-filtered) .ProjectCard:nth-child(4n+4) .ProjectCard-content {
  @include breakpoint(large) {
    display: inline-flex;
    height: auto;
  }
}

// Every 8th card counting from 4 align image on the left
.CardList.CardList--overview.CardList--projects:not(.is-filtered) .ProjectCard:nth-child(8n+4) .ProjectCard-main {
  @include breakpoint(large) {
    grid-column: 9/13;
    order: 2;
  }
}

.CardList.CardList--overview.CardList--projects:not(.is-filtered) .ProjectCard:nth-child(8n+4) .ProjectCard-aside {
  @include breakpoint(large) {
    grid-column: 1 / 9;
    order: 1;
    margin-right: -20px;
  }
}

// Every 8th card counting from 8 align image on the right
.CardList.CardList--overview.CardList--projects:not(.is-filtered) .ProjectCard:nth-child(8n+8) .ProjectCard-main {
  @include breakpoint(large) {
    grid-column: 1 / 5;
    order: 1;
  }
}

.CardList.CardList--overview.CardList--projects:not(.is-filtered) .ProjectCard:nth-child(8n+8) .ProjectCard-aside {
  @include breakpoint(large) {
    grid-column: 5 / 13;
    order: 2;
    margin-left: -20px;
  }
}
