@mixin content-spacing ($sizing, $property-name: 'margin-bottom') {
  @if length($sizing) == 0 {
    @warn '$sizing must be set.';
  }

  $spacing-map: 0;

  @if $sizing == 'small' {
    $spacing-map: $spacing-small;
  } @else if $sizing == 'medium' {
    $spacing-map: $spacing-medium;
  } @else if $sizing == 'large' {
    $spacing-map: $spacing-large;
  }

  @if length($spacing-map) != 0 {
    #{$property-name}: nth(map-values($spacing-map), 1);

    @include breakpoint(medium) {
      #{$property-name}: nth(map-values($spacing-map), 2);
    }

    @include breakpoint(xlarge) {
      #{$property-name}: nth(map-values($spacing-map), 3);
    }
  }
}
