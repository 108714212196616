.AnimatedTitle {
  #{--AnimatedTitle-title-color}: $black;
}

.AnimatedTitle-title {
  @include font-medium();
  margin: 0;
  padding: 0;
  transform: translateZ(0);
  font-family: $font-body;
  font-kerning: none;
  text-align: left;
  text-transform: uppercase;
  text-rendering: optimizeSpeed;
  color: var(--AnimatedTitle-title-color);

  &.one {
    position: absolute;
  }

  &.hidden {
    display: none;
  }
}

.AnimatedTitle.is-vision {
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 20px;
  max-width: $global-width;
  margin: 0 auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};

  .AnimatedTitle-title {
    grid-area: 1 / 1 / 9 / 13;
    margin-top: 160px;
    font-size: rem-calc(36);
    line-height: unitless-calc(42, 36);

    @include breakpoint(medium) {
      margin-top: 180px;
    }
    @include breakpoint(large) {
      grid-area: 1 / 3 / 9 / 13;
      margin-top: 145px;
      font-size: rem-calc(60);
      line-height: unitless-calc(60, 60);
    }
    @include breakpoint(xlarge) {
      margin-top: 165px;
    }

    br {
      display: none;

      @include breakpoint(medium) {
        display: inline;
      }
    }
  }
}

.AnimatedTitle.AnimatedTitle--home {
  font-size: rem-calc(30);

  @include breakpoint(330px) {
    font-size: rem-calc(40);
  }
  @include breakpoint(500px) {
    font-size: rem-calc(60);
  }
  @include breakpoint(medium) {
    font-size: rem-calc(80);
  }
  @include breakpoint(large) {
    font-size: rem-calc(90);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(110);
  }
  @include breakpoint(xxlarge) {
    font-size: rem-calc(120);
  }

  #{--AnimatedTitle-title-color}: $white;
}
