.Conversation {
  @include content-spacing(large, 'margin-top');
  @include content-spacing(large, 'margin-bottom');
  display: block;
  background-color: $smoke;
}

.Conversation-container {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.Conversation-inner {
  @include content-spacing(medium, 'padding-top');
  @include content-spacing(large, 'padding-bottom');
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.Conversation-title {
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 9;
  }

  h2 {
    margin-bottom: 30px;
    font-size: rem-calc(28);
    line-height: 1;
    text-transform: uppercase;

    @include breakpoint(large) {
      margin-bottom: 50px;
      font-size: rem-calc(40);
    }
  }
}

.Conversation-content {
  display: inline-block;
  grid-column: 1 / 4;
  margin-bottom: 40px;

  @include breakpoint(large) {
    grid-column: 3 / 11;
    margin-bottom: 45px;
  }

  p {
    @include font-medium();
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);

    @include breakpoint(large) {
      font-size: rem-calc(20);
      line-height: unitless-calc(26, 20);
    }
  }
}

.Conversation-button {
  display: inline-flex;
  grid-column: 1 / 4;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(large) {
    grid-column: 3 / 11;
  }
}
