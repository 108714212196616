.LinkCard {
  position: relative;
  display: flex;
  flex-direction: column;
  #{--LinkCard-background-color}: $boulder;

  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }

  &:hover {
    .LinkCard-image {
      transform: scale(1.1);
    }
    .LinkCard-title, .LinkCard-label {
      opacity: 0.7;
    }
  }
}

.LinkCard-main {
  position: relative;
  grid-column: 1 / 4;

  @include breakpoint(medium only) {
    height: 100%;
    background-color: var(--LinkCard-background-color);
  }
}

.LinkCard-content {
  padding: 45px 30px 60px 40px;
  background-color: var(--LinkCard-background-color);
}

.LinkCard-label {
  @include font-medium();
  display: inline-block;
  padding-right: 39px;
  text-transform: uppercase;
  color: $black;
  transition: opacity 0.65s cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.LinkCard-labelText {
  display: inline;
  margin-right: 15px;
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);
}

.LinkCard-labelArrow {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: -24px;
  transform: translateY(5px);
  margin-top: -7px;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.LinkCard-title {
  @include font-medium();
  display: flex;
  margin-bottom: 30px;
  font-size: rem-calc(24);
  line-height: unitless-calc(36, 36);
  text-transform: uppercase;
  color: $black;

  transition: opacity 300ms ease-in-out;

  @include breakpoint(medium) {
    margin-bottom: 60px;
    font-size: rem-calc(24);
  }
  @include breakpoint(large) {
    font-size: rem-calc(28);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(32);
  }
}

.LinkCard-aside {
  position: relative;
  display: flex;
  grid-column: 1 / 4;
  flex-direction: column;
  width: auto;
}

.LinkCard-figure {
  position: relative;
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 100%;
  background-color: var(--LinkCard-background-color);
  overflow: hidden;
}

.LinkCard-figure::before {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: percentage(5 / 8);
  content: '';
}

.LinkCard-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-color: var(--LinkCard-background-color);
  filter: grayscale(100%);
  mix-blend-mode: multiply;

  will-change: transform;
  transition: transform .9s cubic-bezier(0.455, 0.030, 0.515, 1);
}

// LinkCard Color variation modifiers

.LinkCard.LinkCard--redRoman {
  #{--LinkCard-background-color}: $red-roman;
}

.LinkCard.LinkCard--yellowFizz {
  #{--LinkCard-background-color}: $yellow-fizz;
}

.LinkCard.LinkCard--greenMoss {
  #{--LinkCard-background-color}: $green-moss;
}

.LinkCard.LinkCard--blueSpindle {
  #{--LinkCard-background-color}: $blue-spindle;
}

// CardList stacked parent component styles
.CardList--stacked .LinkCard {
  @include content-spacing(small, 'margin-bottom');

  @include breakpoint(medium) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.CardList--stacked .LinkCard-main {
  @include breakpoint(medium) {
    width: auto;
  }
}

.CardList--stacked .LinkCard:nth-child(even) .LinkCard-main {
  @include breakpoint(medium) {
    grid-column: 1 / 7;
    order: 1;
    margin-right: -10px;
  }
  @include breakpoint(large) {
    grid-column: 1 / 5;
    margin-right: -20px;
  }
}

.CardList--stacked .LinkCard:nth-child(odd) .LinkCard-main {
  @include breakpoint(medium) {
    grid-column: 7 / 13;
    order: 2;
    margin-left: -10px;
  }
  @include breakpoint(large) {
    grid-column: 9 / 13;
    margin-left: -20px;
  }
}

.CardList--stacked .LinkCard-content {
  @include breakpoint(medium) {
    padding: 35px 30px 50px 40px;
  }
  @include breakpoint(large) {
    padding: 55px 40px 60px 60px;
  }
}

.CardList--stacked .LinkCard-label {
  @include breakpoint(large) {
    padding-right: 44px;
  }
}

.CardList--stacked .LinkCard-labelText {
  @include breakpoint(large) {
    margin-right: 20px;
    font-size: rem-calc(20);
    line-height: unitless-calc(24, 20);
  }
}

.CardList--stacked .LinkCard-title {
  @include break-word();
}

.CardList--stacked .LinkCard:nth-child(even) .LinkCard-aside {
  @include breakpoint(medium) {
    grid-column: 7 / 13;
    order: 2;
    margin-left: -10px;
  }
  @include breakpoint(large) {
    grid-column: 5 / 13;
    margin-left: 0;
  }
}

.CardList--stacked .LinkCard:nth-child(odd) .LinkCard-aside {
  @include breakpoint(medium) {
    grid-column: 1 / 7;
    order: 1;
    margin-right: -10px;
  }
  @include breakpoint(large) {
    grid-column: 1 / 9;
    margin-right: 0;
  }
}


// CardList spread parent component styles
.CardList--spread .LinkCard {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - #{($grid-margin-gutters + 60px)});

  @include breakpoint(medium) {
    min-width: 350px;
  }
}

.CardList--spread .LinkCard-main {
  height: 100%;
}

.CardList--spread .LinkCard-title {
  @include break-word();
}
