// Styles for modal video component
.VideoBlock {
  @include content-spacing(large, 'margin-top');
  @include content-spacing(large, 'margin-bottom');
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.VideoBlock-inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.VideoBlock-title {
  @include content-spacing(small, 'margin-bottom');
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 11;
  }

  h2 {
    font-size: rem-calc(28);
    line-height: 1;
    text-transform: uppercase;

    @include breakpoint(large) {
      margin-top: 20px;
      font-size: rem-calc(40);
    }
  }
}

.VideoBlock-figure {
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 1 / 13;
  }
}

.VideoBlock-figureWrap {
  position: relative;
  display: block;
  width: calc(100% + #{$grid-margin-gutters});
  max-height: 940px;
  margin-right: calc(#{$grid-margin-gutters / 2} * -1);
  margin-left: calc(#{$grid-margin-gutters / 2} * -1);

  @include breakpoint(large) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  &::before {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: percentage(9 / 16);
    content: '';
    background-color: var(--base-preloaderColor);
  }
}

.VideoBlock-figureWrap + .VideoBlock-figureWrap {
  display: none;
}

.VideoBlock-image,
.VideoBlock-video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.VideoBlock-play {
  position: absolute;
  z-index: map_get($z-index, medium);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover .VideoBlock-playWrap {
    background-color: rgba($black, .85);
  }
}

.VideoBlock-playWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  background-color: rgba($black, .7);
  transition: background-color 180ms ease-in;

  @include breakpoint(medium) {
    width: percentage(170 / 1080);
  }

  &::before {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    content: '';
  }
}

.VideoBlock-playIcon {
  position: absolute;
  width: percentage(40 / 170);
  height: auto;
  color: $white;
}
