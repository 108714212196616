.ThemeHeader {
  @include content-spacing(small, 'margin-bottom');
  @include content-spacing(small, 'margin-top');

  @include breakpoint(large) {
    @include content-spacing(medium, 'margin-bottom');
  }

  #{--ThemeHeader-background-color}: transparent;
  #{--ThemeHeader-color}: $black;
}

body.is-transitioning .ThemeHeader {
  opacity: 0;
}

.ThemeHeader-inner {
  max-width: $global-width;
  margin: 0 auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.ThemeHeader-intro {
  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
  }
}

.ThemeHeader-backButtonWrapper {
  position: relative;
  display: flex;
  grid-area: 1 / 1 / 2 / 3;
  align-items: flex-start;
  margin-bottom: 25px;
}

.ThemeHeader-backButton {
  margin-left: -20px;
  padding-left: 20px;
  transition: color 0.1s ease-in-out;

  &:hover {
    color: $blue-spindle;
  }

  svg {
    position: absolute;
    top: 3px;
    left: -20px;
    width: 9px;
    height: 15px;
  }

  span {
    font-size: rem-calc(20);
    line-height: unitless-calc(20, 20);
    text-transform: uppercase;
  }
}

.ThemeHeader-title {
  margin-bottom: 25px;
  font-size: rem-calc(36);
  line-height: unitless-calc(42, 36);
  text-transform: uppercase;

  @include breakpoint(large) {
    grid-column: span 9;
    margin-bottom: 60px;
    transform: translateY(-10px);
    font-size: rem-calc(60);
    line-height: unitless-calc(60, 60);
  }

  br {
    display: none;

    @include breakpoint(medium) {
      display: inline;
    }
  }
}

.ThemeHeader-text {
  grid-column: span 6;
  font-size: rem-calc(20);
  line-height: unitless-calc(26, 20);
}

.ThemeHeader-content {
  margin-bottom: 25px;

  @include breakpoint(large) {
    display: grid;
    grid-area: 1 / 3 / 2 / 13;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(10, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
    margin-bottom: 70px;
  }

  p {
    margin-bottom: 25px;
  }
}

.ThemeHeader-media {
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
  }
}

.ThemeHeader-curator {
  display: flex;
  order: 2;
  justify-content: center;
  align-items: flex-end;
  width: 135px;
  height: 135px;
  margin-right: -30px;
  font-size: 0;
  background-color: var(--ThemeHeader-background-color);

  @include breakpoint(large) {
    order: 1;
    width: 100%;
    height: 70%;
    max-height: 370px;
  };
}

.ThemeHeader-curatorImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ThemeHeader-people {
  display: flex;
  order: 2;
  justify-content: space-between;
  align-items: flex-end;
  transform: translateY(-50%);

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 7;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin-right: -20px;
    transform: translateY(0);
  }
}

.ThemeHeader-curatedBy {
  display: flex;
  flex-direction: column;
  order: 1;
  color: var(--ThemeHeader-color);

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 0;
    width: 100%;
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }
}

.ThemeHeader-author {
  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 7;
  }
}

.ThemeHeader-authorRole {
  @include breakpoint(large) {
    grid-area: 2 / 3 / 3 / 7;
  }
}

.ThemeHeader-imageWrapper {
  order: 1;

  @include breakpoint(large) {
    display: flex;
    grid-area: 1 / 7 / 2 / 13;
    order: 2;
    width: 50vw;
  }

  img {
    width: calc(100vw - 30px);
    min-height: 290px;
    max-height: 400px;
    object-fit: cover;

    @include breakpoint(xlarge) {
      max-height: 530px;
    }
  }
}

.ThemeHeader.ThemeHeader--redRoman {
  #{--ThemeHeader-background-color}: $red-roman;
  #{--ThemeHeader-color}: darken($red-roman, 20%);
}

.ThemeHeader.ThemeHeader--greenMoss {
  #{--ThemeHeader-background-color}: $green-moss;
  #{--ThemeHeader-color}: darken($green-moss, 20%);
}

.ThemeHeader.ThemeHeader--blueSpindle {
  #{--ThemeHeader-background-color}: $blue-spindle;
  #{--ThemeHeader-color}: darken($blue-spindle, 20%);
}

.ThemeHeader.ThemeHeader--yellowFizz {
  #{--ThemeHeader-background-color}: #{$yellow-fizz};
  #{--ThemeHeader-color}: darken($yellow-fizz, 20%);
}
