.LoadMore {
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};

  .is-project & {
    .LoadMore-button {
      color: $white;
      border-color: $white;

      &:focus,
      &:hover {
        color: $aluminum;
        border-color: $aluminum;
      }
    }
  }
}

.LoadMore-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  @include breakpoint(medium) {
    margin-bottom: 80px;
  }
  @include breakpoint(large) {
    margin-bottom: 120px;
  }
}

// LoadMore button
.LoadMore-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 380px;
  padding: 17px 40px 18px;
  color: $black;
  border: 2px solid $black;
  transition: color 150ms ease-in-out, border-color 150ms ease-in-out;

  &:focus,
  &:hover {
    cursor: pointer;
    color: $white;
    border-color: $white;
  }
}

.LoadMore-buttonLabel {
  margin-right: 15px;
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
}

.LoadMore-svg {
  width: 14px;
  height: 14px;
  margin-top: 4px;
}


.LoadMore--people {
  transform: translateY(-100%);

  .LoadMore-button {
    max-width: 280px;
  }
}

// Load more button will align left on desktop
.LoadMore.is-left {
  @include breakpoint(large) {
    .LoadMore-inner {
      justify-content: flex-start;
    }
  }
}
