.Table {
  @include content-spacing(large, 'margin-bottom');
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.Table-inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.Table-title {
  @include content-spacing(small, 'margin-bottom');
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 9;
  }

  h2 {
    font-size: rem-calc(28);
    line-height: 1;
    text-transform: uppercase;

    @include breakpoint(large) {
      margin-top: 20px;
      font-size: rem-calc(40);
    }
  }
}

.Table-content {
  @include content-spacing(small, 'margin-bottom');
  display: inline-block;
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 11;
  }
}

.Table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);
  border-top: 1px solid var(--base-borderColor);

  @include breakpoint(large) {
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }

  &:first-child {
    border-top-width: 0;
  }
}

.Table-label {
  width: 33.3%;
  padding: 24px 10px 24px 0;
  color: $jumbo;

  @include breakpoint(large) {
    width: 50%;
  }
}

.Table-value {
  width: 66.7%;
  padding: 24px 0 24px 10px;

  @include breakpoint(large) {
    width: 50%;
  }
}

.Table-buttons {
  display: inline-flex;
  grid-column: 1 / 4;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(large) {
    grid-column: 3 / 11;
  }
}

.Table.Table--ruler .Table-inner::after {
  @include content-spacing(medium, 'margin-top');
  position: relative;
  display: inline-block;
  grid-column: 1 / 4;
  width: 100%;
  height: 2px;
  content: '';
  background-color: var(--base-rulerColor);

  @include breakpoint(large) {
    grid-column: 3 / 13;
  }
}
