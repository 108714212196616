.Navigation {
  position: sticky;
  z-index: map_get($z-index, menu);
  top: 0px;
  transition: box-shadow 150ms ease-in-out;
  will-change: box-shadow;
}

/* 
.Notification-banner {
   background-color:#E8FA49; 
   color: #000; 
   height: 45px; 
   display: block;
   line-height: 45px;
   position: absolute;
   top: -45px; 
   width: 100%;
   text-align: center;
   padding: 0px 10px;
   overflow: hidden;
}

@media only screen and (max-width: 910px) {
   .Navigation {
      top: 39px;
   }
    
   .Notification-banner {
      height: 40px; 
      display: inline-block;
      line-height: 40px;
      position: absolute;
      top: -40px; 
   }   

   .Notification-banner .Notification-text {
      white-space: nowrap; 
      animation: scroll-left 10s linear infinite;
      width: 100%;
   }
   
   
   @keyframes scroll-left {
      0% { transform: translateX(100%); } 
      100% { transform: translateX(-235%); } /
   }
}
*/


.Navigation-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $global-width;
  height: 75px;
  margin: 0 auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};

  @include breakpoint(large) {
    justify-content: flex-start;
    height: 95px;
  }
}

.Navigation-items {
  display: flex;
  visibility: hidden;
  align-items: center;
  margin-left: auto;
  opacity: 0;
  transition: opacity 250ms ease-in-out 450ms;

  .is-home & {
    transition-delay: 350ms;
  }
}

.Navigation-item {
  display: none;
  font-size: rem-calc(20);
  text-transform: uppercase;
  pointer-events: none;

  @include breakpoint(large) {
    display: inline-flex;
    margin-right: 80px;
  }
  @include breakpoint(xlarge) {
    margin-right: 90px;
  }

  &.Navigation-logo {
    display: block;
    opacity: 1;
  }

  .Navigation-svg,
  a {
    color: var(--base-textColor);
    transition: color 0.1s ease-in-out;

    &::after {
      transition: background-color 150ms ease-in-out;
    }

    &:hover {
      @include breakpoint(large) {
        color: $blue-spindle;

        &::after {
          background-color: $blue-spindle;
        }

        .is-home & {
          color: $yellow-fizz;

          &::after {
            background-color: $yellow-fizz;
          }
        }

        // hover variation for generic pages
        .is-generic & {
          color: $white;

          &::after {
            background-color: $white;
          }
        }
      }
    }
  }
}

.Navigation-item.Navigation-logo {
  position: absolute;
  z-index: map_get($z-index, logo);
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  max-width: $global-width;
  height: calc(var(--vh, 1vh) * 100);
  margin-right: auto;
  margin-left: auto;

  @include breakpoint($global-width) {
    left: auto;
  }

  .Navigation.is-visible & {
    pointer-events: none;
  }

  .Navigation.is-open & {
    height: 100%;
  }
}

.Navigation-logoLink {
  position: relative;
  display: inline-block;
  width: auto;
  height: 18px;
  margin-top: 32px;
  margin-left: 30px;
  pointer-events: all;

  @include breakpoint(large) {
    display: block;
    width: auto;
    margin-top: 42px;
    margin-left: 30px;
  }
  @include breakpoint(1240px) {
    margin-left: 0;
  }

  .Navigation.is-visible & {
    max-width: 330px;
  }
}

.Navigation-trigger {
  @include font-medium();
  z-index: map_get($z-index, menu);
  display: inline-flex;
  align-items: center;
  margin-right: 0;
  padding: 0;
  text-transform: uppercase;
  color: var(--base-textColor);
  border: 0;
  background-color: transparent;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.Navigation-svg {
  width: 18px;
  height: 14px;

  @include breakpoint(large) {
    width: 15px;
    transform: translateY(1px);
  }
}

// Submenu styles
.Navigation-submenu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  transition: right 0.35s cubic-bezier(0.2, 0.030, 0.515, 1);

  @include breakpoint(medium) {
    transition-duration: 0.55s;
  }

}

.Navigation-submenuInner {
  overflow: auto;
  max-width: $global-width;
  height: 100%;
  margin: 0 auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
  background-color: $yellow-fizz;

  @include breakpoint(large) {
    display: grid;
    overflow: visible;
    grid-template-rows: 95px 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
    background-color: transparent;
  }
}

.Navigation-submenuHead {
  position: absolute;
  z-index: map_get($z-index, menu);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 60px);
  height: 75px;
  background-color: $yellow-fizz;

  @include breakpoint(large) {
    position: relative;
    display: grid;
    grid-area: 1 / 9 / 2 / 13;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
    width: 100%;
    height: 95px;
    background-color: transparent;
  }
}

.Navigation-submenuLabel {
  display: none;
  font-size: rem-calc(20);
  line-height: unitless-calc(23, 20);
  text-transform: uppercase;

  @include breakpoint(large) {
    display: inline;
    grid-area: 1 / 2 / 2 / 5;
  }
}

.Navigation-submenuContent {
  position: relative;
  padding-top: 75px;
  padding-bottom: 30px;

  @include breakpoint(large) {
    display: grid;
    grid-area: 2 / 9 / 3 / 13;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
    padding: 30px;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    margin-left: #{($grid-margin-gutters / 2) * -1};
    content: ' ';
    transform: translateY(-75px);
    background-color: $yellow-fizz;

    @include breakpoint(large) {
      left: 0;
      margin-left: 0;
      transform: translateY(-95px);
    }
  }
}

.Navigation-submenuContentInner {
  @include breakpoint(large) {
    display: flex;
    overflow: auto;
    grid-area: 1 / 2 / 2 / 5;
    flex-direction: column;
    justify-content: center;
    height: auto;
    transform: translateY(-10%);
  }
}

.Navigation-submenuClose {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;

  @include breakpoint(large) {
    display: flex;
    grid-area: 1 / 4 / 2 / 5;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    &:hover {
      cursor: pointer;
      color: rgba($black, .5);
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.Navigation-submenuSvg {
  width: 18px;
  height: 18px;

  @include breakpoint(large) {
    width: 15px;
    height: 15px;
  }
}

.Navigation-submenuMain {
  margin-bottom: 50px;

  @include breakpoint(large) {
    display: none;
  }

  .Navigation-submenuItem {
    font-size: rem-calc(36);
    line-height: unitless-calc(60, 36);
  }
}

.Navigation-submenuSecondary {
  margin-bottom: 50px;

  @include breakpoint(large) {
    grid-column: 2;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.Navigation-submenuItem {
  font-size: rem-calc(28);
  line-height: unitless-calc(60, 28);
  text-transform: uppercase;

  a {
    color: $black;
    transition: color 150ms ease-in-out;

    @include breakpoint(large) {
      &:hover {
        color: rgba($black, .5);
      }
    }
  }
}

// Background overlay
.Navigation-overlay {
  position: fixed;
  z-index: map_get($z-index, menu);
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  background-color: rgba($black, .7);
  transition: opacity .5s;
}

// State
.Navigation.is-scrolling {
  background-color: var(--base-navigationColor);
  box-shadow: 0 0 45px 25px rgba($black, .15);

  @include breakpoint(large) {
    box-shadow: 0 0 85px 40px rgba($black, .2);
  }
}

.Navigation-item.is-active {
  a {
    position: relative;

    &::after {
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 2px;
      content: ' ';
      background-color: var(--base-textColor);
    }
  }
}

.Navigation.is-open {
  .Navigation-overlay {
    visibility: visible;
    opacity: 1;
  }

  .Navigation-submenu {
    z-index: 1111;
    right: 0;
    display: inline;
  }

  .Navigation-inner {
    position: relative;
  }

  .Navigation-item.Navigation-logo {
    position: relative;
    z-index: 2000;
  }

  .Navigation-logoLink {
    margin-left: 0;
  }

  .Logo .Logo-part {
    @include breakpoint(large) {
      transition: color 150ms ease-in-out;

      #{--base-textColor}: $white;
    }

    #{--base-textColor}: $black;
  }
}

.Navigation.is-visible {
  .Navigation-item {
    pointer-events: all;
  }
  .Navigation-items {
    visibility: visible;
    opacity: 1;
  }
}

// body.is-home .Navigation {
//   @todo comment it out unsure this will come up eventually
//   &.is-scrolling {
//    box-shadow: 0 0 45px 25px rgba($white, .15);
//    @include breakpoint(large) {
//      box-shadow: 0 0 85px 40px rgba($white, .2);
//    }
//   }
// }

// body.is-project {
//   .Navigation {
//     @todo comment it out unsure this will come up eventually
//     &.is-scrolling {
//      box-shadow: 0 0 45px 25px rgba($white, .15);
//      @include breakpoint(large) {
//        box-shadow: 0 0 85px 40px rgba($white, .2);
//      }
//     }
//   }
// }
