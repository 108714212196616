.Logo {
  position: absolute;
  display: inline-block;
  margin: 0;
  padding: 0;
  transform-origin: 0 0;
  font-size: 0;
  opacity: 0;
}

.Logo-part-container {
  position: absolute;
  display: inline-block;
  overflow: hidden;
}

.Logo-part-container.one {
  transform: translateX(60px);
}

.Logo-part-container.two {
  left: 128px;
}

.Logo-part-container.three {
  left: 441px;
}

.Logo-part-container.four {
  top: -1px;
  left: 532px;
}

.Logo-part-container.five {
  top: -1px;
  left: 675px;
}

.Logo-part-container.six {
  left: 1026px;
  transform: translateX(-218px);
}

.Logo-part-container.arch {
  left: 1170px;
}

.Logo-part {
  display: block;
  height: 82px;
  color: var(--base-textColor);
  background: none;
  transition: color .2s;
}

.Logo-part.one {
  width: 115px;
  transform: translateX(59px);
}

.Logo-part.two {
  width: 300px;
  transform: translateX(60px);
}

.Logo-part.three {
  width: 76px;
}

.Logo-part.four {
  width: 151px;
  height: 84px;
}

.Logo-part.five {
  width: 303px;
  height: 84px;
  transform: translateX(-178px);
}

.Logo-part.six {
  width: 109px;
  transform: translateX(-60px);
}

.Logo-part.arch {
  width: 695px;
  transform: translateX(-695px);
}
