.PeopleCard {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @include breakpoint(medium) {
    margin-bottom: 40px;
  }
  @include breakpoint(large) {
    margin-bottom: 50px;
  }
}

.PeopleCard.PeopleCard--link {
  transition: color 90ms ease-in;

  &:hover {
    color: $red-cinnabar;
    outline: none;
    transition-duration: 0ms;
  }
}


.PeopleCard-main {
  position: relative;
  display: flex;
  height: 100%;
}

.PeopleCard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 25px 0;

  @include breakpoint(medium) {
    padding: 30px 30px 30px 0;
  }
}

.PeopleCard-name {
  @include font-medium();
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: rem-calc(24);
  line-height: unitless-calc(24, 24);
  text-transform: uppercase;

  @include breakpoint(375px) {
    font-size: rem-calc(28);
  }
  @include breakpoint(small down) {
    @include break-word();
  }
  @include breakpoint(medium) {
    font-size: rem-calc(26);
  }
  @include breakpoint(large) {
    font-size: rem-calc(30);
  }
}

.PeopleCard-label {
  @include font-medium();
  display: inline-block;
  text-transform: uppercase;
}

.PeopleCard-labelText {
  display: inline;
  font-size: rem-calc(16);
  line-height: unitless-calc(20, 16);

  @include breakpoint(375px) {
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);
  }
  @include breakpoint(medium) {
    font-size: rem-calc(20);
    line-height: unitless-calc(24, 20);
  }
}

.PeopleCard-labelArrow {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: -20px;
  transform: translateY(1px);

  @include breakpoint(375px) {
    width: 22px;
    height: 22px;
    margin-right: -22px;
  }
  @include breakpoint(medium) {
    width: 24px;
    height: 24px;
    margin-right: -24px;
  }
}

.PeopleCard-aside {
  position: relative;
  width: auto;
}

.PeopleCard-figure {
  position: relative;
  display: flex;
  height: auto;
  min-height: 100%;
  background-color: var(--base-preloaderColor);
}

.PeopleCard-figure::before {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: percentage(1 / 1);
  content: '';
}

.PeopleCard-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
