.HomeHeader {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.HomeHeader.is-visible {
  display: flex;
}

.HomeHeader-inner {
  width: 100%;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};

  @include breakpoint(medium) {
    width: auto;
    padding: 0;
  }
}

.HomeHeader-buttons {
  overflow: hidden;
  width: 100%;
  height: 0;
  margin-top: 30px;

  @include breakpoint(medium) {
    margin-top: 40px;
  }

  .HomeHeader-buttons.show {
    height: 62px;
  }
}

.HomeHeader-buttonGroup {
  align-items: flex-start;

  @include breakpoint(medium) {
    width: auto;
  }

  .CtaButton {
    display: inline-flex;
    margin-bottom: 0;

    @include breakpoint(small only) {
      width: 50%;
      max-width: 160px;
      padding: 17px 25px 18px 35px;

      .CtaButton-label {
        text-transform: capitalize;

        & > span {
          display: none;
        }
      }
    }
  }
}
