.CtaButton {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-width: 0;
  margin-bottom: 40px;
  padding: 17px 40px 18px;
  color: $white;
  border: 2px solid $black;
  background: $black;
  //transition: color 150ms cubic-bezier(.25,.46,.45,.94), background 150ms cubic-bezier(.25,.46,.45,.94); // TODO ek - turned off animation, don't like it. Need to come up with alternative

  &:last-child {
    margin-bottom: 0;
  }

  &:focus,
  &:hover {
    cursor: pointer;
    color: $black;
    background: transparent;
  }
}

.CtaButton-label {
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
}

.CtaButton-arrow {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-bottom: -2px;
  line-height: unitless-calc(22, 20);
}

.CtaButton.CtaButton--hollow {
  color: $black;
  background: transparent;

  &:focus,
  &:hover {
    color: $white;
    background: $black;
  }
}

.CtaButton.CtaButton--white {
  color: $black;
  border: 2px solid $white;
  background: $white;

  &:focus,
  &:hover {
    color: $black;
    border-color: $yellow-fizz;
    background: $yellow-fizz;
  }
}

.CtaButton.CtaButton--black {
  &:focus,
  &:hover {
    color: $black;
    border-color: $yellow-fizz;
    background: $yellow-fizz;
  }
}
