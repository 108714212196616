.Header {
  @include content-spacing(small, 'margin-top');
  @include content-spacing(small, 'margin-bottom');
  color: var(--base-textColor);
}

.Header-inner {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 20px;
  }
}

.Header-title {
  margin-bottom: 30px;
  font-size: rem-calc(36);
  line-height: unitless-calc(36, 36);
  text-transform: uppercase;

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 4;
    font-size: rem-calc(40);
    line-height: unitless-calc(40, 40);
  }

  //exception when no description is set for header
  &:last-child {
    margin-bottom: 0;
  }
}

.Header-description {
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);

  @include breakpoint(medium) {
    max-width: 50vw;
  }
  @include breakpoint (large) {
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }

  p {
    margin-bottom: 15px;

    @include breakpoint(large) {
      margin-bottom: 25px;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.Header-content {
  @include breakpoint(large) {
    grid-area: 1 / 4 / 2 / 11;
  }

  .Header--vision & {
    @include breakpoint(large) {
      grid-area: 1 / 5 / 2 / 11;
    }
  }

}



.Header-svgBack {
  width: 15px;
  height: 15px;
  transform: translate(0px, 8px);
  margin-right: 10px;

  @include breakpoint(large) {
    margin-right: 0;
    transform: translate(-30px, 8px);
  }
}
.Header-back {
  text-transform: uppercase;
  display: flex;
  grid-area: 1/1/2/3;
  font-size: rem-calc(20);
  line-height: unitless-calc(28, 20);
  margin-bottom: 15px;

  @include breakpoint(large) {
    position: absolute;
  }

  &:hover {
    cursor: pointer;
  }
}
.Header-backLabel {
  @include breakpoint(large) {
    transform: translateX(-15px);
  }
}

.Header-published {
  grid-area: 1/11/2/13;
  text-align: right;
  font-size: rem-calc(20);
  line-height: unitless-calc(28, 20);
  text-transform: uppercase;
}


// Modifiers
.Header.Header--column {
  .Header-title {
    @include breakpoint(large) {
      grid-area: 1 / 3 / 2 / 10;
    }
  }
  .Header-content {
    @include breakpoint(large) {
      grid-area: 2 / 3 / 3 / 11;
    }
  }
}

.Header + .ImageBlock {
  margin-top: 0;

  @include breakpoint(medium only) {
    margin-top: -10px;
  }
}
