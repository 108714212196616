// Sass variables used throughout the project
@import 'base/variables';

// Project specific mixins
@import 'mixins/mixins';

// NPM modules used for base styling resets and built-in mixins
@import '~normalize.css/normalize.css';
@import '~reset-css/reset.css';

// Foundation is used so that we can use its powerful mixins
@import '~foundation-sites/scss/foundation';

// External stylesheet by Typekit to add Gill Sans font-family
// TODO: Consider to move this out of Momkai account & install webfonts on the server
@import url('https://use.typekit.net/lww1jrc.css');

// Base styling of common elements
@import 'base/base';
@import 'base/typography';
@import 'base/error';

// Project specific components
@import '../components/AnimatedTitle/AnimatedTitle';
@import '../components/AwardCard/AwardCard';
@import '../components/BackgroundVideo/BackgroundVideo';
@import '../components/CardList/CardList';
@import '../components/Conversation/Conversation';
@import '../components/CtaButton/CtaButton';
@import '../components/EmptyState/EmptyState';
@import '../components/Filter/Filter';
@import '../components/Footer/Footer';
@import '../components/Gallery/Gallery';
@import '../components/Header/Header';
@import '../components/HomeHeader/HomeHeader';
@import '../components/HomeIntro/HomeIntro';
@import '../components/LinkCard/LinkCard';
@import '../components/LoadMore/LoadMore';
@import '../components/ImageBlock/ImageBlock';
@import '../components/Logo/Logo';
@import '../components/Navigation/Navigation';
@import '../components/NewsCard/NewsCard';
@import '../components/PageTransition/PageTransition';
@import '../components/PeopleCard/PeopleCard';
@import '../components/ProjectHeader/ProjectHeader';
@import '../components/ProjectCard/ProjectCard';
@import '../components/Quote/Quote';
@import '../components/Table/Table';
@import '../components/Text/Text';
@import '../components/ThemeCard/ThemeCard';
@import '../components/ThemeHeader/ThemeHeader';
@import '../components/VideoBlock/VideoBlock';
@import '../components/VideoBlock/VideoBlockModal';
