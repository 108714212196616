.Text {
  @include content-spacing(large, 'margin-bottom');
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.Text-inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.Text-title {
  @include content-spacing(small, 'margin-bottom');
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 9;
  }

  h2 {
    font-size: rem-calc(28);
    line-height: 1;
    text-transform: uppercase;

    @include breakpoint(large) {
      margin-top: 20px;
      font-size: rem-calc(40);
    }
  }
}

.Text-content {
  display: inline-block;
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 11;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 30px;
    font-size: rem-calc(22);
    line-height: 1;
    text-transform: uppercase;

    @include breakpoint(large) {
      font-size: rem-calc(25);
    }
  }

  p + h3 {
    margin-top: 50px;

    @include breakpoint(large) {
      margin-top: 70px;
    }
  }

  p,
  ul,
  ol {
    margin-bottom: 25px;
  }

  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }

  li,
  p {
    @include font-medium();
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);

    @include breakpoint(large) {
      font-size: rem-calc(20);
      line-height: unitless-calc(26, 20);
    }

    a {
      @include font-medium();
      text-decoration-line: underline;
      color: var(--base-textColor);
      transition: color 90ms ease-in;

      &:focus,
      &:hover {
        color: $red-cinnabar;
        outline: none;
        transition-duration: 0ms;
      }
    }
  }

  li {
    position: relative;
    padding: 24px 0 24px 20px;
    border-top: 1px solid $iron;

    @include breakpoint(large) {
      padding-left: 25px;
    }

    &:first-child {
      border-top-width: 0;
    }
  }

  .Text-title + & > ul:first-child,
  .Text-title + & > ol:first-child {
    margin-top: -24px;
  }

  ul li {
    &::before {
      position: absolute;
      top: 24px;
      left: 0;
      display: flex;
      content: '\2022';
    }
  }

  ol {
    counter-reset: item;

    li::before {
      position: absolute;
      left: 0;
      display: flex;
      content: counter(item) '. ';
      counter-increment: item;
      font-variant-numeric: lining-nums;

      font-feature-settings: 'tnum';
    }
  }
}

.Text-buttons {
  display: inline-flex;
  grid-column: 1 / 4;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;

  @include breakpoint(large) {
    grid-column: 3 / 11;
    margin-top: 80px;
  }
}

.Text.Text--right {
  .Text-title {
    @include breakpoint(large) {
      grid-column: 3 / 6;
    }
    h2 {
      margin-top: 0;
    }
  }

  .Text-content {
    @include breakpoint(large) {
      grid-column: 7 / 13;
    }
  }

  .Text-buttons {
    @include breakpoint(large) {
      grid-column: 7 / 13;
    }
  }
}

.Text.Text--ruler .Text-inner::after {
  @include content-spacing(medium, 'margin-top');
  position: relative;
  display: inline-block;
  grid-column: 1 / 4;
  width: 100%;
  height: 2px;
  content: '';
  background-color: var(--base-rulerColor);

  @include breakpoint(large) {
    grid-column: 3 / 13;
  }
}
