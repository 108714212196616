a {
  text-decoration: none;
  color: var(--base-textColor);
}

b,
strong {
  @include font-bold();

  i,
  em {
    @include font-bold-italic();
  }
}

i,
em {
  @include font-medium-italic();

  b,
  strong {
    @include font-bold-italic();
  }
}
