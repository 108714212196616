.CardList {
  display: block;
  overflow: hidden;

  #{--CardList-navigationBackground}: rgba($black, .7);
  #{--CardList-navigationBackgroundHover}: rgba($black, .85);
  #{--CardList-navigationGradientStart}: rgba($white, 0);
  #{--CardList-navigationGradientEnd}: rgba($white, 0);
  #{--CardList-navigationIconColor}: $white;
  #{--CardList-paginationColor}: $black;

  &:first-child {
    @include content-spacing(small, 'margin-top');
  }
}

.CardList-inner {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.CardList-container {
  position: relative;
  overflow: hidden;
}

.CardList-wrapper {
  position: relative;
}

.CardList-title {
  padding: 0 0 38px;

  @include breakpoint(medium) {
    position: absolute;
    z-index: 1;
    top: -28px;
    left: 7px;
    padding: 20px 20px 15px;
    transform: translate(2px, 2px);
    background-color: $gainsboro;
  }

  h1 {
    @include font-medium();
    font-size: rem-calc(36);
    line-height: unitless-calc(36, 36);
    word-spacing: 100vw; // force line break after every word
    text-transform: uppercase;

    @include breakpoint(large) {
      font-size: rem-calc(40);
    }
  }
}

.CardList-heading {
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 3 / 9;
  }

  h2 {
    margin-bottom: 30px;
    font-size: rem-calc(28);
    line-height: 1;
    text-transform: uppercase;

    @include breakpoint(medium) {
      margin-bottom: 50px;
    }
    @include breakpoint(large) {
      margin-bottom: 70px;
      font-size: rem-calc(40);
    }
  }
}

.CardList-navigation {
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  width: 100px;
  cursor: pointer;
  opacity: 0;
  outline: 0;
  transition: opacity 180ms ease-in;

  @include breakpoint(large) {
    display: flex;
  }

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }
}

.CardList-container--initialized .CardList-navigation:not(.is-disabled) {
  pointer-events: all;
  opacity: 1;
}

.CardList-navigation.is-disabled {
  pointer-events: none;
}

.CardList-navigation.CardList-navigation--prev {
  left: 0;
}

.CardList-navigation.CardList-navigation--prev::before {
  background-image: linear-gradient(to left, var(--CardList-navigationGradientStart), var(--CardList-navigationGradientEnd));
}

.CardList-navigation.CardList-navigation--next {
  right: 0;
}

.CardList-navigation.CardList-navigation--next::before {
  background-image: linear-gradient(to right, var(--CardList-navigationGradientStart), var(--CardList-navigationGradientEnd));
}

.CardList-navigationButton {
  position: absolute;
  z-index: 10;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  pointer-events: none;
  background-color: var(--CardList-navigationBackground);
  transition: background-color 180ms ease-in;

  &:hover {
    background-color: var(--CardList-navigationBackgroundHover);
  }
}

.CardList-navigation--prev .CardList-navigationButton {
  left: 0;
  padding-right: 5px;
}

.CardList-navigation--next .CardList-navigationButton {
  right: 0;
  padding-left: 5px;
}

.CardList-navigation.CardList-navigation--prev:active .CardList-navigationButton {
  padding-right: 7px;
}

.CardList-navigation.CardList-navigation--next:active .CardList-navigationButton {
  padding-left: 7px;
}

.CardList-navigationIcon {
  width: 14px;
  height: 24px;
  color: var(--CardList-navigationIconColor);
}

.CardList-pagination {
  position: absolute;
  z-index: 10;
  top: -7px;
  left: 25px;
  width: 100%;
  transform: translate3d(0,0,0);
  text-align: left;
  transition: 300ms opacity;

  @include breakpoint(large) {
    display: none;
  }
}

.CardList-paginationBullet {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 5px;
  cursor: pointer;
  opacity: 1;
  border: 1px solid var(--CardList-paginationColor);
  border-radius: 50%;
  background: transparent;
  transition: background-color 180ms ease-in;
}

.CardList-paginationBullet--active {
  opacity: 1;
  background: var(--CardList-paginationColor);
}


// CardList Themes modifier
.CardList--themes {
  @include content-spacing(small, 'padding-top');
  background-color: $white;
}

// CardList Projects modifier
.CardList--projects {
  @include content-spacing(small, 'padding-top');
  background-color: $black;

  #{--base-textColor}: $white;
  #{--CardList-navigationBackground}: $white;
  #{--CardList-navigationBackgroundHover}: $smoke;
  #{--CardList-navigationIconColor}: $black;
  #{--CardList-paginationColor}: $white;
  #{--CardList-navigationGradientStart}: rgba($black, 0);
  #{--CardList-navigationGradientEnd}: rgba($black, .66);
}

// CardList Projects & Overview combination modifier
.CardList--overview.CardList--projects {
  padding-top: 0;
  padding-bottom: 10px;

  @include breakpoint(medium) {
    padding-bottom: 20px;
  }
  @include breakpoint(large) {
    padding-bottom: 30px;
  }
}

// CardList News modifier
.CardList--news {
  @include content-spacing(small, 'padding-top');
  background-color: $gainsboro;
}

// CardList Awards modifier
.CardList--awards {
  @include content-spacing(medium, 'padding-bottom');
  background-color: $gainsboro;

  // offset for stacked awardscards bottom margin
  .CardList--generic + & {
    margin-top: -20px;
  }
}

// CardList People modifier
.CardList--people {
  @include content-spacing(medium, 'padding-bottom');
  background-color: $gainsboro;
}

.CardList--people .CardList-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(medium) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// CardList Spread modifier
.CardList.CardList--spread {
  @include content-spacing(small, 'padding-bottom');
}

.CardList.CardList--spread.is-active .CardList-inner {
  @include breakpoint(medium down) {
    padding-right: 0;
    padding-left: 0;
  }
}

.CardList--spread .CardList-wrapper {
  display: grid;
  grid-column-gap: 20px;
  grid-auto-flow: column;
}

.CardList--spread .CardList-container.CardList-container--initialized {
  @include breakpoint(medium down) {
    padding-top: 40px;
  }
}

.CardList--spread .CardList-container--initialized .CardList-wrapper {
  grid-column-gap: 0;
}

// CardList Overview modifier
.CardList--overview .CardList-wrapper {
  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-auto-rows: auto;
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}


// TODO remove demo-wrappers, used only to stack components in component overview
.temp-generic-demo-wrapper {
  @include content-spacing(small, 'padding-top');
  background-color: $gainsboro;
}

div.temp-project-demo-wrapper {
  @include content-spacing(small, 'padding-top');
  background-color: $black;

  #{--base-textColor}: $white;
  #{--base-backgroundColor}: $black;
  #{--base-navigationColor}: $black;
  #{--base-preloaderColor}: $jet;
  #{--base-borderColor}: $steel;
  #{--base-rulerColor}: $jet;

  .CardList {
    margin-top: 0;
  }
}
