.AwardCard {
  position: relative;
  margin-bottom: 20px;

  #{--AwardCard-background-color}: $white;
}

.AwardCard-main {
  position: relative;
  display: flex;
  grid-column: 1 / 4;
}

.AwardCard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 40px;
  background-color: var(--AwardCard-background-color);
  min-height: 380px;
  width: 100%;
}

.AwardCard-label {
  @include font-medium();
  display: inline-block;
  padding-right: 39px;
  text-transform: uppercase;
}

.AwardCard-labelText {
  display: inline;
  margin-right: 10px;
  font-size: rem-calc(20);
  line-height: unitless-calc(18, 20);
}

.AwardCard-labelArrow {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: -24px;
  transform: translateY(5px);
  margin-top: -7px;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.AwardCard-title {
  @include font-medium();
  @include break-word();
  position: relative;
  display: block;
  margin-bottom: 40px;
  font-size: rem-calc(22);
  line-height: unitless-calc(28, 28);
  text-transform: uppercase;


  @include breakpoint(medium) {
    margin-bottom: 60px;
    font-size: rem-calc(24);
  }

  @include breakpoint(xlarge) {

  }

  @include breakpoint(xlarge) {
    margin-bottom: 80px;
    font-size: rem-calc(30);
  }
}

// AwardCard Color variation modifiers
.AwardCard.AwardCard--redRoman {
  #{--AwardCard-background-color}: $red-roman;
}

.AwardCard.AwardCard--yellowFizz {
  #{--AwardCard-background-color}: $yellow-fizz;
}

.AwardCard.AwardCard--greenMoss {
  #{--AwardCard-background-color}: $green-moss;
}

.AwardCard.AwardCard--blueSpindle {
  #{--AwardCard-background-color}: $blue-spindle;
}

// CardList overview parent component styles
.CardList--overview .AwardCard {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    min-width: 50%;
  }

  @include breakpoint(large) {
    min-width: 0;
  }
}

.CardList--overview .AwardCard-main {
  height: 100%;
}



// CardList spread parent component styles
.CardList--spread .AwardCard {
  @include content-spacing(small, 'margin-bottom');
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - #{($grid-margin-gutters + 60px)});

  @include breakpoint(medium) {
    min-width: 350px;
  }
}

.CardList--spread .AwardCard-main {
  height: 100%;
}

.CardList--spread .AwardCard-title {
  @include break-word();
}
