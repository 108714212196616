.EmptyState {
  display: block;
}

.EmptyState-container {
  position: relative;
  max-width: $global-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: #{$grid-margin-gutters / 2};
  padding-left: #{$grid-margin-gutters / 2};
}

.EmptyState-inner {
  @include content-spacing(small, 'margin-top');
  @include content-spacing(large, 'margin-bottom');
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @include breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.EmptyState-title {
  grid-column: 1 / 4;

  @include breakpoint(large) {
    grid-column: 1 / 9;
  }

  h2 {
    margin-bottom: 30px;
    font-size: rem-calc(36);
    line-height: 1;
    text-transform: uppercase;

    @include breakpoint(large) {
      margin-bottom: 30px;
      font-size: rem-calc(40);
    }
  }
}

.EmptyState-description {
  display: inline-block;
  grid-column: 1 / 4;
  margin-bottom: 40px;

  @include breakpoint(large) {
    grid-column: 1 / 9;
    margin-bottom: 45px;
  }

  p {
    @include font-medium();
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);

    @include breakpoint(large) {
      font-size: rem-calc(20);
      line-height: unitless-calc(26, 20);
    }
  }
}

.EmptyState-reset {
  display: inline-flex;
  grid-column: 1 / 4;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(large) {
    grid-column: 1 / 9;
  }
}

.EmptyState-button {
  @include font-medium();
  display: inline-flex;
  align-items: center;
  padding: 17px 40px 19px;
  cursor: pointer;
  font-size: rem-calc(18);
  line-height: unitless-calc(20, 18);
  border: 2px solid var(--base-textColor);
  outline: none;
  background-color: var(--base-textColor);

  @include breakpoint(large) {
    padding: 17px 50px 19px;
    font-size: rem-calc(20);
    line-height: unitless-calc(22, 20);
  }

  &:focus,
  &:hover {
    color: var(--base-textColor);
    background: transparent;
  }
}
