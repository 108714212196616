.BackgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.BackgroundVideo-top {
  position: absolute;
  width: 100%;
  height: 50%;
  background: $black;
}

.BackgroundVideo-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: $black;
}

.BackgroundVideo-media {
  position: absolute;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: var(--base-preloaderColor);

  & video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}

.BackgroundVideo-media.is-visible {
  display: block;
}
