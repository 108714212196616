// Global site max-width and gutters used in calculations
$global-width: 1240px; // added 60px more to the grid to align with the spacing on the sides
$grid-margin-gutters: 60px; // divided by 2 added left and right of xy-grid (1240 - 60 = 1180 grid width)

// Breakpoints used by Foundation breakpoint media query mixin
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

// Body font supplied by Typekit
$font-body: 'gill-sans-nova', 'Gill Sans', Arial, sans-serif;

// Spacing values defined in Sketch design used in content margin mixin
$spacing-small: (min: 40px, mid: 60px, max: 80px);
$spacing-medium: (min: 60px, mid: 90px, max: 120px);
$spacing-large: (min: 80px, mid: 120px, max: 160px);

// Z-index map variable to prevent overlapping z-indexes
$z-index: (
  negative: -1,
  small: 0,
  medium: 50,
  large: 100,
  xlarge: 250,
  xxlarge: 500,
  logo: 750,
  menu: 1000,
  modal: 5000,
  overlay: 7500
);

// Project colors (http://chir.ag/projects/name-that-color/)
$red-cinnabar: #E54247;
$red-roman: #D75757;
$yellow-fizz: #E8FA48;
$green-moss: #A1D2AD;
$blue-spindle: #C3E6EF;

// Shades of grey (partially copied from foundation)
$white: #FFFFFF;
$smoke: #EEEEEE;
$gainsboro: #DDDDDD;
$boulder: #DADADA;
$iron: #CCCCCC;
$base: #AAAAAA;
$aluminum: #999999;
$jumbo: #888888;
$steel: #666666;
$charcoal: #555555;
$tuatara: #444444;
$oil: #333333;
$jet: #222222;
$black: #000000;
